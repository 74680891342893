<template>
	<div class="helpcenter">
		<div class="wrap">
			<div class="helpcenter__title">Вопрос - ответ</div>
			<div class="helpcenter__buttons">
				<label
					v-for="item in faq"
					:key="item.id"
					:for="`faq-${item.id}`"
					:class="['helpcenter__tab', tab === item.id ? 'active' : '']"
				>
					{{ item.title }}
					<input
						type="radio"
						v-model="tab"
						:value="item.id"
						name="profile-faq-tabs"
						:id="`faq-${item.id}`"
					/>
				</label>
				<!-- <button class="btn helpcenter__button__trip active">
					Путешествия
				</button>
				<button class="btn helpcenter__button__receiving-guests">
					Прием гостей
				</button> -->
			</div>
		</div>

		<form
			@submit.prevent.stop
			method="get"
			class="helpcenter__form"
			id="helpcenter__form"
		>
			<input
				type="search"
				v-model="search"
				class="helpcenter__form__input"
				name="helpcenter__search"
				placeholder="Поиск"
				autocomplete="off"
        :disabled="faq.length === 0"
			/>
		</form>

		<template v-if="content && content.questions">
			<div
				v-for="(item, index) in content.questions"
				:key="item.id"
				class="question-answer__accordion"
			>
				<input
					type="checkbox"
					:id="`accordion-${index}`"
					name="profile-faq-content-radio"
				/>
				<label :for="`accordion-${index}`">{{ item.q }}</label>
				<article>
					<p>{{ item.a }}</p>
				</article>
			</div>
		</template>

		<!-- <a href="feedback" class="btn helpcenter__button__problem"
			>Моя проблема не решена</a
		> -->
	</div>
</template>

<script>
export default {
	name: 'Faq',
	data() {
		return {
			faq: [],
			tab: 1,
			search: '',

      getAbortController: new AbortController(),
		}
	},
	computed: {
		content() {
			if (this.search) {
				const data = { ...this.faq.find((itm) => itm.id === this.tab) }
				data.questions = data.questions.filter((itm) =>
					this.search
						.toLowerCase()
						.split(' ')
						.every((v) => itm.q.toLowerCase().includes(v))
				)
				return data
			} else {
				return this.faq.find((itm) => itm.id === this.tab)
			}
		}
	},
	async mounted() {
		const response = await this.$api.faq.get({ controller: this.getAbortController })

		if (response.status) {
			this.faq = response.response
		}
	},
  beforeUnmount() {
    this.getAbortController.abort()
  }
}
</script>
